import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useWindowSize } from "../../hooks/window-size";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import "./Footer.scss";
import loadable from "@loadable/component";
import $ from "jquery";
import logo from "../../images/starberry-logo-opt.png"
import CookieConsent from "react-cookie-consent";
// import FeedBackModal from "../FeedBackModal/FeedBackModal"
// import GenerateLink from "../common/site/GetLink";
import parse from 'html-react-parser';
// import CookieConsent from "react-cookie-consent";
const GenerateLink = loadable(() => import("../common/site/GetLink"));
// const CookieConsent = loadable(() => import("react-cookie-consent"));
const NewsletterForm = loadable(() => import("../forms/newsletter-form"));

// import NewsletterForm from "../forms/newsletter-form";
// import logo from "../../images/starberry-logo.png"

// const logo = loadable(() => import("../../images/starberry-logo-opt.png"));
// import ScrollAnimation from 'react-animate-on-scroll';
const FeedBackModal = loadable(() => import("../FeedBackModal/FeedBackModal"));

const PopularSearch = loadable(() => import("../PopularSearch/PopularSearch"));

const Footer = (props) => {
  const [windowWidth] = useWindowSize();
  const [cookie, setCookie] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => setIsModalOpen(false)
  const data = useStaticQuery(graphql`
  query  {  
    glstrapi {
      globalConfig {
        Explore_Menus {
          id
          Label
          Menu {
            Primary_URL
          }
        }
        Facebook_Link
        Footer_Address
        Instagram_Link
        Twitter_Link
        Youtube_Link
        Linkedin_Link
        Tiktok_Link
      }
    }
  }
  `)
  var footer_item = data.glstrapi.globalConfig;
  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    setTimeout(function () {
      if ($('.cookie-popup .CookieConsent').length > 0) {
        $('.whatsapp-floating-btn').addClass('with_cookie-popup');
      }
      setCookie(true);
      $('.cookieok').click(function (event) {
        $('.acceptcookie').click();
        $('.whatsapp-floating-btn').removeClass('with_cookie-popup');
      });
    }, 500);


    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)  
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  
  }, [])

  
  const year = new Date().getFullYear();
  return (
    <React.Fragment>
       {renderComponent && (  <PopularSearch popularsearch={props?.popularsearch} areaName={props?.areaName} />   )}
     
      <footer className="footer-section">
        <Container>
          <Row>
            <Col md={12}>
              <div className="footer-content">
                <div className="footer-lists">
                  <div className="listing-wrap">
                    <h4>explore</h4>
                    <ul className="listing">
                   
                      {footer_item && footer_item.Explore_Menus.map((item) => (
                        <li>
                          {renderComponent && (
                            <GenerateLink link={item.Menu.Primary_URL} class="link">
                              {item.Label}
                            </GenerateLink>
                          )}
                        </li>
                      ))}
                       <li>
                       <a class="link" href="javascript:void;" onClick={()=>{  setIsModalOpen(true)}}>Feedback</a>
                       </li>
                     
                      
                    </ul>
                  </div>
                  <div className="listing-wrap">
                    <h4>social</h4>
                    <ul className="listing social-links">
                      <li>
                        <a href={footer_item.Twitter_Link} className="link" target={`_blank`}>
                          <i className="icon-twitter"></i>
                          Twitter
                        </a>
                      </li>
                      <li>
                        <a href={footer_item.Facebook_Link} className="link" target={`_blank`}>
                          <i className="icon-fb"></i>
                          Facebook
                        </a>
                      </li>
                      <li>
                        <a href={footer_item.Instagram_Link} className="link" target={`_blank`}>
                          <i className="icon-insta"></i>
                          Instagram
                        </a>
                      </li>
                      <li>
                        <a href={footer_item.Youtube_Link} className="link" target={`_blank`}>
                          <i className="icon-yt"></i>
                          Youtube
                        </a>
                      </li>
                      <li>
                        <a href={footer_item.Linkedin_Link} className="link" target={`_blank`}>
                          <i className="icon-linkedin-white"></i>
                          Linkedin
                        </a>
                      </li>
                      <li>
                        <a href={footer_item.Tiktok_Link} className="link" target={`_blank`}>
                          <i className="icon-tiktok-white"></i>
                          Tiktok
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="listing-wrap full">
                    <h4>contact details</h4>
                    <div className="details-wrap">
                      {parse(footer_item.Footer_Address)}
                      
                    </div>
                  </div>
                </div>
                <div className="footer-form">
                  <h4>never miss an update!</h4>
                  <p>
                    Join our newsletter and never miss an update about the Dubai property market.
                  </p>
                  <div className="form-wrap">
                    {renderComponent && ( <NewsletterForm />   )}
                                      
                  </div>
                </div>
                <div className="footer-links">
                  <div className="left-panel">
                    <div className="links-column">
                      <Link className="link" to="/terms-and-conditions/">

                        Terms &amp; Conditions
                      </Link>{" "}
                      /{" "}
                      <Link className="link" to="/privacy-policy/">
                        Privacy Policy
                      </Link>{" "}
                      /{" "}
                      <Link className="link" to="/cookie-policy/">
                        Cookies Policy
                      </Link>
                    </div>
                    <div className="copyright">
                      &copy; {year} McCone Properties. All Rights Reserved
                    </div>
                  </div>
                  <span className="site-text">
                    Site by
                    <Link
                      href="https://www.starberry.tv/"
                      target="_blank"

                    >{" "}
                      <img src={logo} alt="starberry logo" style={{ height: "18px" }} width ={20} height={20} loading="lazy"/>
                      {" "}
                      Starberry
                    </Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    
      {cookie && 
        <section className="cookie-popup">
          <CookieConsent
            location="bottom"
            buttonText="Accept Cookies"
            cookieName="CookiePolicy"
            buttonClasses="acceptcookie"
            overlay={false}
          // acceptOnScrollPercentage={1}
          //onAccept={()=> ClosePopup()}
          >
            <div className="topbar-block">

              <p>
                We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to='/cookie-policy/'>Cookie Policy</Link>. <a href="javascript:void(0);" class="cookieok">Accept Cookies</a>
              </p>
              <i className="icon-close-dark"></i>
            </div>
          </CookieConsent> 
        </section>
      }
      {renderComponent &&
        <FeedBackModal isModalOpen={isModalOpen} closeModal={closeModal} />
        }
    </React.Fragment>
  );
};
export default Footer;
